const Logger = {
  _isEnabled: false, // default value
  
  set isEnabled(value) {
    console.trace('Logger.isEnabled was set to', value); // Will print a stack trace showing where it was set
    this._isEnabled = value;
  },
  
  get isEnabled() {
    return this._isEnabled;
  },
  
  log: function(...args) {
    if (this.isEnabled) {
      console.log(...args);
    }
  },
  
  error: function(...args) {
    if (this.isEnabled) {
      console.error(...args);
    }
  },
};
var identityWindow;
var NFTsoldOutMessage = "Brütes are sold out!"
var nftSocket;
var selectedQuote = 1;
var myTimer;
let mintType = 'mint';
function createNFTCard(data, type, freeSkip, freeMint){
      Logger.log(freeSkip)
      Logger.log(freeMint)
      var traits = {}
      if(freeSkip == "1") {
        localStorage.setItem("freeSkip", "1")
      } else {
        localStorage.setItem("freeSkip", "0")
      }
      if(freeMint == "1") {
        localStorage.setItem("freeMint", "1")
      } else {
        localStorage.setItem("freeMint", "0")
      }
      for (const [key, value] of Object.entries(data)) {
        //Logger.log(key, value);
        if(key.startsWith('trait_')) {
          //Logger.log("We have a trait");
          //Logger.log(key);
          //Logger.log(value);
          if(value != 'none' && key != 'trait_trait_count'){
            traits[key.slice(6)] = value;
          };

        }
      }
      //Logger.log(traits);
      
      //let traitString = [...traits].map(([k, v]) => `${k}: ${v}`).join(" • ");
      let traitString = `${Object.keys(traits).map(key => `${key}: ${traits[key]}`).join(' • ')}`;
      var nftID = data.id
      if(type == "Preview"){
        var title = 'BRÜTE PREVIEW'
        if(localStorage.getItem("freeSkip") == '1'){
          var skipPrice = "(FREE!)"
  
        } else {
          var skipPrice = "(0.2 DeSo)"
        }
        var mintPrice = ""
        if(localStorage.getItem("freeMint") == '1'){
          var mintPrice = "(FREE!)"
        }
        var skipOrViewButton = "<button onclick='skip();' class='button is-link is-fullwidth is-outlined'>Skip it! " + skipPrice +"</a>"
        var mintButton = "<button onclick='newmint();' class='button is-primary is-fullwidth punk-button'>Mint it! " + mintPrice + "</button>"
      } else {
        PostHashHex = data.PostHashHex
        var title = 'BRÜTE #' + nftID
        var skipOrViewButton = "<a class='button is-link is-fullwidth is-outlined' href='https://nftz.zone/nft/" + PostHashHex + "/' target='_blank'>View NFT</a>"
        var mintButton = "<button onclick='initiatemint();' class='button is-primary is-fullwidth punk-button'>Mint Another</button>"

      }
      var imageURL = data.ImageURL
      var traitCount = data.trait_trait_count

      nftCard = "\
  <div class='nft'>\
    <div class='main'>\
        <h1 class='title is-light'>" + title + "</h1>\
        <img class='tokenImage' src='" + imageURL + "' height='1024' width='1024' alt='" + title + "'>\
        <div class='has-text-centered'>\
        <div class='accordion'>\
            <div class='accordion-option'>\
                <input type='checkbox' id='toggle1' class='accordion-toggle' />\
                <label class='accordion-title' for='toggle1'>" + traitCount + " Traits\
                </label>\
                <div class='accordion-content'>\
                    <p>" + traitString + "</p>\
                </div>\
            </div>\
            </div>\
                      </div>\
                      <div class='columns mt-3'>\
                           <div class='column'>" + skipOrViewButton + "</div>\
                           <div class='column'>" + mintButton + "</div>\
                         </div>\
    </div>\
</div>"
return nftCard;
}

function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}
function clock(time) {
  myTimer = setInterval(myClock, 1000);
  var c = time;


  function myClock() {
    --c
    const quotes = [
      "Building…",
      "Processing…"]
    var seconds = minTwoDigits(c % 60); // Seconds that cannot be written in minutes
    var minutes = (c - seconds) / 60; // Gives the seconds that COULD be given in minutes
    var minutesLeft = minutes % 60; // Minutes that cannot be written in hours
    var hours = (minutes - minutesLeft) % 60;
    if (seconds % 2 == 0) {
      //Logger.log("New Quote")

      selectedQuote = selectedQuote + 1;
      //Logger.log(selectedQuote)
      if (selectedQuote > quotes.length) {
        selectedQuote = 0;
      }
      //Logger.log(quotes[selectedQuote])
    }
    // Now in hours, minutes and seconds, you have the time you need.
    $('#processing-quote').text(quotes[selectedQuote])
    $('#clock').html("Wait time: " + hours + ":" + minutes + ":" + seconds);
    if (c == 0) {
      clearInterval(myTimer);
      c = 1;
      $('#clock').html("Processing...you may want to check your DeSo account and see if your mint was successful and reload this page.");
    }
  }
}

function sendMessage(ws, msg) {
  // Wait until the state of the socket is not ready and send the message when it is...
  waitForSocketConnection(ws, function () {
    Logger.log("message sent!!!");
    Logger.log(msg);
    ws.send(msg);
  });
}

// Make the function wait until the connection is made...
function waitForSocketConnection(socket, callback) {
  setTimeout(
    function () {
      if (socket.readyState === 1) {
        Logger.log("Connection is made")
        if (callback != null) {
          callback();
        }
      } else {
        Logger.log("wait for connection...")
        waitForSocketConnection(socket, callback);
      }

    }, 5); // wait 5 milisecond for the connection...
}

function logout() {
  var logoutURL = 'https://identity.deso.org/logout?publicKey=' + localStorage.getItem("userPublicKey");
  identityWindow = window.open(logoutURL, null, 'toolbar=no, width=800, height=1000, top=0, left=0');
}

//Set this DeSo Value to just the Mint Price
function login() {
  mintType = 'mint';
  $("#minted-nft").addClass("hidden")
  $("#bid-status").addClass("hidden")
  //$('html, body').animate({
  //  scrollTop: $("#instructions").offset().top
  //}, 1000);
  $("#login-button").addClass("is-loading");
  identityWindow = window.open('https://identity.deso.org/log-in', null, 'toolbar=no, width=800, height=1000, top=0, left=0');    
  //identityWindow = window.open('https://identity.deso.org/derive?expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:10170000,%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
}

function newmint() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#topofmint").offset().top
}, 1000);
  mintType = 'mint';
  $("#login-button").addClass("is-loading");
  $("#bid-status").addClass("hidden", 500)
  $("#minted-nft").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  document.getElementById("instructions").classList.add('hidden');
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  $("#bid-button").removeClass("hidden", 500)
  Logger.log("Clicked Select Mint");
  $("#ban-status").addClass("hidden", 500)
  var mintPrice = "1000700000"
  if(localStorage.getItem("freeMint") == "1") {
    var mintPrice = "1170000"
  }
  if(localStorage.getItem("userPublicKey") != "") {
    identityWindow = window.open('https://identity.deso.org/derive?publicKey=' + localStorage.getItem("userPublicKey") +'&expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + mintPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  } else {
    identityWindow = window.open('https://identity.deso.org/derive?expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + mintPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  }
  
}

// Set this DeSo Value to + Mint Price
// 4 Basic Transfers
function skip() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#topofmint").offset().top
}, 1000);
  mintType = 'skip';
  $("#login-button").addClass("is-loading");
  $("#bid-status").addClass("hidden", 500)
  $("#minted-nft").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  document.getElementById("instructions").classList.add('hidden');
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  $("#bid-button").removeClass("hidden", 500)
  if(localStorage.getItem("freeSkip") == '1'){
    var theSkipPrice = "10170000"

  } else {
    var theSkipPrice = "200200000"
  }
  ///identityWindow = window.open('https://identity.deso.org/log-in', null, 'toolbar=no, width=800, height=1000, top=0, left=0');    
  if(localStorage.getItem("userPublicKey") != "") {
    identityWindow = window.open('https://identity.deso.org/derive?publicKey=' + localStorage.getItem("userPublicKey") +'&expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + theSkipPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  } else {
    identityWindow = window.open('https://identity.deso.org/derive?expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + theSkipPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  }
  
}

function bid() {
  // Open Identity Window
  //identityWindow = window.open('loadin.html', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  $("#bid-status").addClass("hidden", 500)
  $("#minted-nft").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  document.getElementById("instructions").classList.add('hidden');
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  Logger.log("Clicked Mint");
  var userPublicKey = localStorage.getItem("userPublicKey");
  var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");
  var derivedSeedHex = localStorage.getItem("derivedSeedHex");
  var derivedJWT = localStorage.getItem("derivedJWT");
  var accessSignature = localStorage.getItem("accessSignature");
  var expirationblock = localStorage.getItem("expirationBlock");
  var transactionSpendingLimitHex = localStorage.getItem("transactionSpendingLimitHex");
  $("#ban-status").addClass("hidden", 500)




  nftSocket = new WebSocket("wss://gug5ltumv8.execute-api.us-west-2.amazonaws.com/production");
  nftSocket.onopen = function (e) {
    Logger.log("Connected to socket");

    Logger.log("Sending first message to initate mint");
    //nftSocket.send(message);

    //var data = {
    //  action : "update"}
    //var message = JSON.stringify(data);
    //socket.send(message);
    //var message = JSON.stringify({action: "approve-bid-queue", message: "Hello"});
    //socket.send(message);
  };
  //var nftName = $("#name").val(); 
  var nftName = "";
  //var seelz = $('input[name="seelz"]:checked').val();
  var dao = "";
  var data = {
    action: "mintwebsocket",
    type: "website",
    nftName: nftName,
    dao: dao,
    userPublicKey: userPublicKey,
    derivedPublicKey: derivedPublicKey,
    derivedJWT: derivedJWT,
    derivedSeedHex: derivedSeedHex,
    accessSignature: accessSignature,
    expirationblock: expirationblock,
    transactionSpendingLimitHex: transactionSpendingLimitHex
  }
  var message = JSON.stringify(data);
  sendMessage(nftSocket, message)
  sendMessage(nftSocket, JSON.stringify({ action: 'update' }))




  nftSocket.onmessage = function (event) {
    Logger.log("Message received from websocket in bid");
    Logger.log(event.data);
    data = JSON.parse(event.data);
    Logger.log(data.Status);
    if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "NFTs are sold out!") {
      $("#clock").addClass("hidden")
      clearInterval(myTimer)
      $("#bid-status").removeClass("hidden", 500).html("<p class='fw-bold error'>" + NFTsoldOutMessage + "</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")
    } else if (data.Status == "Complete") {
      // This isn't used for the first mint
      Logger.log("Completed minting")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      Logger.log("NFT Attributes  1")
      Logger.log(data)
      nftCard = createNFTCard(data.Attributes, "Mint", "0", "0")
     

                              //var joinedBackgroundExtras = [BackgroundExtras1, BackgroundExtras2, BackgroundExtras3, BackgroundExtras4, BackgroundExtras5].filter(name => !name.includes('None')).join(', ');
                              //var joinedBodywear = [Bodywear, Bodywear1, Bodywear2, Bodywear3, Bodywear4].filter(name => !name.includes('None')).join(', ');
                              //var joinedAccessories = [Accessories, Accessories1, Accessories2, Accessories3].filter(name => !name.includes('None')).join(', ');
                              //var joinedHeadwear = [Headware, Headware1, Headware2].filter(name => !name.includes('None')).join(', ');
                              //var joinedEyes = [Eyes, Eyes1].filter(name => !name.includes('None')).join(', ');
                              //var joinedFace = [Face, Face1].filter(name => !name.includes('None')).join(', ');


      //var nftID = data.Attributes.Edition;
      //var traitCount = data.Attributes.TraitCount;
      //var rarityScore = data.Attributes.RarityScore;
      //var imageURL = data.Attributes.ImageURL;
      //var PostHashHex = data.Attributes.PostHashHex;
    

      

      document.getElementById("instructions").classList.add('hidden');
      $("#bid-status").removeClass("hidden").html("<p>1 You've minted a BRÜTE!</p>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      Logger.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#seelz-no").prop("checked", true);
      $('#bid-button').html("Mint one more")
      $('#bid-button').removeClass("hidden")
      $('#login-button').removeClass("is-loading")
      document.getElementById("bid").classList.remove('hidden');
      
      //Confetti
      var count = 200;
      var defaults = {
        origin: { x: 0.5 }
      };

      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 0 }
      });
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 0 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 1 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 0 }
      });

      

    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      //$( "#wait-time", identityWindow.window.document.body ).html(queueWaitTime)
      //identityWindow.document.$("#wait-time").html(queueWaitTime)
      //identityWindow.window.document.body.innerHTML
      //identityWindow.document.getElementById("time").innerText = queueWaitTime;
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)


    } else if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      document.getElementById("instructions").classList.add('hidden');

    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)
    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#clock").addClass("hidden")
      $("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      $("#instructions").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#login-button").html("Login with DeSo")
      $("#login-button").removeClass("is-loading")
      $("#login").removeClass("hidden", 500)
    } else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      //localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      nftSocket.close()
    } else if (data.Status == "NFTs are sold out!") {
      identityWindow.close()
      nftSocket.close()
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>BRÜTE is sold out!</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")


    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#instructions").addClass("hidden", 500)
      $("#options").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.Error + "</p>")
      setTimeout(function () {
        $("#bid-status").addClass("hidden", 500)
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").html("Mint NFT")
        $("#options").removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
      }, 10e3);

      Logger.log("Thinks there was an error")
      identityWindow.close()
    }
    else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.message + "</p>")

      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }


  };


}

// Using with BRUTE
function skipMint() {
  // User has selected to skip previewed NFT
  $("#bid-status").addClass("hidden", 500)
  $("#minted-nft").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  document.getElementById("instructions").classList.add('hidden');
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  $("#bid").removeClass("hidden", 500)
  Logger.log("Clicked Skip");
  var userPublicKey = localStorage.getItem("userPublicKey");
  var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");
  var derivedSeedHex = localStorage.getItem("derivedSeedHex");
  var derivedJWT = localStorage.getItem("derivedJWT");
  var accessSignature = localStorage.getItem("accessSignature");
  var expirationblock = localStorage.getItem("expirationBlock");
  var transactionSpendingLimitHex = localStorage.getItem("transactionSpendingLimitHex");
  $("#ban-status").addClass("hidden", 500)




  nftSocket = new WebSocket("wss://gug5ltumv8.execute-api.us-west-2.amazonaws.com/production");
  nftSocket.onopen = function (e) {
    Logger.log("Connected to socket");

    Logger.log("Sending message to initiate paid skip");
    //nftSocket.send(message);

    //var data = {
    //  action : "update"}
    //var message = JSON.stringify(data);
    //socket.send(message);
    //var message = JSON.stringify({action: "approve-bid-queue", message: "Hello"});
    //socket.send(message);
  };
  //var nftName = $("#name").val(); 
  var nftName = "";
  //var seelz = $('input[name="seelz"]:checked').val();
  var dao = "";
  var data = {
    action: "initiatepaidskip",
    type: "website",
    nftName: nftName,
    dao: dao,
    userPublicKey: userPublicKey,
    derivedPublicKey: derivedPublicKey,
    derivedJWT: derivedJWT,
    derivedSeedHex: derivedSeedHex,
    accessSignature: accessSignature,
    expirationblock: expirationblock,
    transactionSpendingLimitHex: transactionSpendingLimitHex
  }
  var message = JSON.stringify(data);
  sendMessage(nftSocket, message)
  sendMessage(nftSocket, JSON.stringify({ action: 'update' }))




  nftSocket.onmessage = function (event) {
    Logger.log("Message received from websocket in bid");
    Logger.log(event.data);
    data = JSON.parse(event.data);
    Logger.log(data.Status);
    if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Initiating Skip") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "NFTs are sold out!") {
      $("#clock").addClass("hidden")
      clearInterval(myTimer)
      $("#bid-status").removeClass("hidden", 500).html("<p class='fw-bold error'>" + NFTsoldOutMessage + "</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")
    } else if (data.Status == "Initiated") {
      Logger.log("Initiated")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      Logger.log("NFT Attributes 2")
      nftCard = createNFTCard(data.Attributes, "Preview", data.FreeSkip, data.FreeMint)

                              //var joinedBackgroundExtras = [BackgroundExtras1, BackgroundExtras2, BackgroundExtras3, BackgroundExtras4, BackgroundExtras5].filter(name => !name.includes('None')).join(', ');
                              //var joinedBodywear = [Bodywear, Bodywear1, Bodywear2, Bodywear3, Bodywear4].filter(name => !name.includes('None')).join(', ');
                              //var joinedAccessories = [Accessories, Accessories1, Accessories2, Accessories3].filter(name => !name.includes('None')).join(', ');
                              //var joinedHeadwear = [Headware, Headware1, Headware2].filter(name => !name.includes('None')).join(', ');
                              //var joinedEyes = [Eyes, Eyes1].filter(name => !name.includes('None')).join(', ');
                              //var joinedFace = [Face, Face1].filter(name => !name.includes('None')).join(', ');



      document.getElementById("instructions").classList.add('hidden');
      $("#bid-status").removeClass("hidden").html("<h1 class='subtitle is-bold is-light'><b>Here's a preview of your BRÜTE!<br>Do you want to mint it or skip it?</b></h1>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      Logger.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#seelz-no").prop("checked", true);
      $('#bid-button').addClass("hidden")
      $("#login-button").html("Preview Mint")
      $('#login').addClass("hidden")
      $('#login-button').removeClass("is-loading")
      $("#bid-button").html("Preview Mint")
      document.getElementById("bid").classList.remove('hidden');
      
      
      

    } else if (data.Status == "Complete") {
      // This isn't used for the first mint
      Logger.log("Completed minting")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      Logger.log("NFT Attributes  1")
      Logger.log(data)
      nftCard = createNFTCard(data.Attributes, "Preview", data.FreeSkip, data.FreeMint)

      document.getElementById("instructions").classList.add('hidden');
      //NOT USED
      $("#bid-status").removeClass("hidden").html("<h1 class='subtitle is-bold is-light'><b>You've minted a BRÜTE!</b></h1>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      Logger.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#seelz-no").prop("checked", true);
      $('#bid-button').html("Mint one more")
      $('#login-button').removeClass("is-loading")
      document.getElementById("bid").classList.remove('hidden');
      
      //Confetti
      var count = 200;
      var defaults = {
        origin: { x: 0.5 }
      };

      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 0 }
      });
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 0 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 1 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 0 }
      });



    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      //$( "#wait-time", identityWindow.window.document.body ).html(queueWaitTime)
      //identityWindow.document.$("#wait-time").html(queueWaitTime)
      //identityWindow.window.document.body.innerHTML
      //identityWindow.document.getElementById("time").innerText = queueWaitTime;
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)


    } else if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      document.getElementById("instructions").classList.add('hidden');

    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)
    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#clock").addClass("hidden")
      $("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      $("#instructions").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#login-button").html("Login with DeSo")
      $("#login-button").removeClass("is-loading")
      $("#login").removeClass("hidden", 500)
    } else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      //localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      nftSocket.close()
    } else if (data.Status == "NFTs are sold out!") {
      identityWindow.close()
      nftSocket.close()
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>BRÜTE is sold out!</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")


    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#instructions").addClass("hidden", 500)
      $("#options").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.Error + "</p>")
      setTimeout(function () {
        $("#bid-status").addClass("hidden", 500)
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").html("Mint NFT")
        $("#options").removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
      }, 10e3);

      Logger.log("Thinks there was an error")
      identityWindow.close()
    }
    else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.message + "</p>")

      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }


  };

  /*
  $.ajax({
      url: "https://z57p02t3oc.execute-api.us-west-2.amazonaws.com/production/mint-and-bid",
      type: "post",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(data),

      beforeSend: function(data) {
        // nothing
        Logger.log("beforeSend");
    },

    success: function (data) {
        Logger.log("Here's the TransactionHex");
        Logger.log(data);
        Logger.log("here's data 0");
        Logger.log(data[0]);
        // Created Transaction, now verify it
        if(typeof data[0] == 'undefined') {
          identityWindow.close();
          Logger.log("it is undefined");
          $("#bid-status").removeClass("hidden", 500).html('<p>Sorry, there was an error. Please try again later.</p>');
          $("#bid-button").html("Try Again");
          setTimeout(function() {
            $("#bid-status").addClass("hidden", 500);
        }, 5e3);
        Logger.log("Thinks there was an error");
        return false;
        }
        if(data == "No NFTs Available") {
          identityWindow.close();
          $("#bid-status").removeClass("hidden").html('<p>Sorry, there are no more NFTs available.</p>');
          $("#bid-button").addClass("hidden", 500);
          setTimeout(function() {
            $("#bid-status").addClass("hidden", 500);
        }, 5e3);
        Logger.log("No more NFTs");
        return false;
        }

        var transactionHex = data[0];
        var nftAddress = data[1];
        localStorage.setItem("transactionHex", transactionHex);
        localStorage.setItem("nftAddress", nftAddress);
        
        Logger.log("Trying to change location of identity window");
        identityWindow.location.href='https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
        //identityWindow = window.open('https://identity.deso.org/approve?tx='
        Logger.log("Thinks it sent")
        $("#bid-button").html("Mint NFT")
        $("#bid").addClass("hidden", 500)
        $("#instructions").addClass("hidden", 500)
        $("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")
      },
    error: function (jqXHR, textStatus, errorThrown) {
        // show an error message
        $("#bid-status").removeClass("hidden", 500).html('<p>Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status + '</p>')
        setTimeout(function() {
            $("#bid-status").addClass("hidden", 500)
        }, 5e3);
        $("#bid").removeClass("hidden", 500)
        Logger.log("Thinks there was an error")
    }
});
*/


}



function selectMint() {
  // User has selected to mint the previewed NFT
  
  var userPublicKey = localStorage.getItem("userPublicKey");
  var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");
  var derivedSeedHex = localStorage.getItem("derivedSeedHex");
  var derivedJWT = localStorage.getItem("derivedJWT");
  var accessSignature = localStorage.getItem("accessSignature");
  var expirationblock = localStorage.getItem("expirationBlock");
  var transactionSpendingLimitHex = localStorage.getItem("transactionSpendingLimitHex");
  




  nftSocket = new WebSocket("wss://gug5ltumv8.execute-api.us-west-2.amazonaws.com/production");
  nftSocket.onopen = function (e) {
    Logger.log("Connected to socket");

    Logger.log("Sending first message to initate mint");
    //nftSocket.send(message);

    //var data = {
    //  action : "update"}
    //var message = JSON.stringify(data);
    //socket.send(message);
    //var message = JSON.stringify({action: "approve-bid-queue", message: "Hello"});
    //socket.send(message);
  };
  //var nftName = $("#name").val(); 
  var nftName = "";
  //var seelz = $('input[name="seelz"]:checked').val();
  var dao = "";
  var data = {
    action: "selectmintwebsocket",
    type: "website",
    nftName: nftName,
    dao: dao,
    userPublicKey: userPublicKey,
    derivedPublicKey: derivedPublicKey,
    derivedJWT: derivedJWT,
    derivedSeedHex: derivedSeedHex,
    accessSignature: accessSignature,
    expirationblock: expirationblock,
    transactionSpendingLimitHex: transactionSpendingLimitHex
  }
  var message = JSON.stringify(data);
  sendMessage(nftSocket, message)
  sendMessage(nftSocket, JSON.stringify({ action: 'update' }))




  nftSocket.onmessage = function (event) {
    Logger.log("Message received from websocket in bid");
    Logger.log(event.data);
    data = JSON.parse(event.data);
    Logger.log(data.Status);
    if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "NFTs are sold out!") {
      $("#clock").addClass("hidden")
      clearInterval(myTimer)
      $("#bid-status").removeClass("hidden", 500).html("<p class='fw-bold error'>" + NFTsoldOutMessage + "</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")
    } else if (data.Status == "Complete") {
      // This isn't used for the first mint
      Logger.log("Completed minting")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      Logger.log("NFT Attributes  1")
      Logger.log(data)
      nftCard = createNFTCard(data.Attributes, "Mint", "0", "0")
      document.getElementById("instructions").classList.add('hidden');
      $("#bid-status").removeClass("hidden").html("<h1 class='subtitle is-bold is-light'><b>You've minted a BRÜTE!</b></h1>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      Logger.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#seelz-no").prop("checked", true);
      $('#bid-button').html("Mint one more")
      $('#login-button').removeClass("is-loading")
      document.getElementById("bid").classList.remove('hidden');
      
      //Confetti
      var count = 200;
      var defaults = {
        origin: { x: 0.5 }
      };

      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 0 }
      });
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 0 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 1 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 0 }
      });


    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      //$( "#wait-time", identityWindow.window.document.body ).html(queueWaitTime)
      //identityWindow.document.$("#wait-time").html(queueWaitTime)
      //identityWindow.window.document.body.innerHTML
      //identityWindow.document.getElementById("time").innerText = queueWaitTime;
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)


    } else if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      document.getElementById("instructions").classList.add('hidden');

    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)
    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#clock").addClass("hidden")
      $("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      $("#instructions").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#login-button").html("Login with DeSo")
      $("#login-button").removeClass("is-loading")
      $("#login").removeClass("hidden", 500)
    } else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      //localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      nftSocket.close()
    } else if (data.Status == "NFTs are sold out!") {
      identityWindow.close()
      nftSocket.close()
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>BRÜTE is sold out!</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")


    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#instructions").addClass("hidden", 500)
      $("#options").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.Error + "</p>")
      setTimeout(function () {
        $("#bid-status").addClass("hidden", 500)
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").html("Mint NFT")
        $("#options").removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
      }, 10e3);

      Logger.log("Thinks there was an error")
      identityWindow.close()
    }
    else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.message + "</p>")

      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }


  };

}

// ALLOW SKIPS for BRUTES
function initiatemint() {
  // Open Identity Window
  //identityWindow = window.open('loadin.html', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#topofmint").offset().top
}, 1000);
  $("#bid-status").addClass("hidden", 500)
  $("#minted-nft").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  document.getElementById("instructions").classList.add('hidden');
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  Logger.log("Clicked Mint");
  var userPublicKey = localStorage.getItem("userPublicKey");
  var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");
  var derivedSeedHex = localStorage.getItem("derivedSeedHex");
  var derivedJWT = localStorage.getItem("derivedJWT");
  var accessSignature = localStorage.getItem("accessSignature");
  var expirationblock = localStorage.getItem("expirationBlock");
  var transactionSpendingLimitHex = localStorage.getItem("transactionSpendingLimitHex");
  $("#ban-status").addClass("hidden", 500)


 


  nftSocket = new WebSocket("wss://gug5ltumv8.execute-api.us-west-2.amazonaws.com/production");
  nftSocket.onopen = function (e) {
    Logger.log("Connected to socket");

    Logger.log("Sending first message to initate mint");
    //nftSocket.send(message);

    //var data = {
    //  action : "update"}
    //var message = JSON.stringify(data);
    //socket.send(message);
    //var message = JSON.stringify({action: "approve-bid-queue", message: "Hello"});
    //socket.send(message);
  };
  //var nftName = $("#name").val(); 
  var nftName = "";
  //var seelz = $('input[name="seelz"]:checked').val();
  var dao = "";
  var data = {
    action: "initiatemintwebsocket",
    type: "website",
    nftName: nftName,
    dao: dao,
    userPublicKey: userPublicKey,
    derivedPublicKey: derivedPublicKey,
    derivedJWT: derivedJWT,
    derivedSeedHex: derivedSeedHex,
    accessSignature: accessSignature,
    expirationblock: expirationblock,
    transactionSpendingLimitHex: transactionSpendingLimitHex
  }
  var message = JSON.stringify(data);
  sendMessage(nftSocket, message)
  sendMessage(nftSocket, JSON.stringify({ action: 'update' }))




  nftSocket.onmessage = function (event) {
    Logger.log("Message received from websocket in bid");
    Logger.log(event.data);
    data = JSON.parse(event.data);
    Logger.log(data.Status);
    if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "NFTs are sold out!") {
      $("#clock").addClass("hidden")
      clearInterval(myTimer)
      $("#bid-status").removeClass("hidden", 500).html("<p class='fw-bold error'>" + NFTsoldOutMessage + "</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")
    } else if (data.Status == "Initiated") {
      Logger.log("Initiated")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      Logger.log("NFT Attributes 2")
      nftCard = createNFTCard(data.Attributes, "Preview", data.FreeSkip, data.FreeMint)

      document.getElementById("instructions").classList.add('hidden');
      $("#bid-status").removeClass("hidden").html("<h1 class='subtitle is-bold is-light'><b>Here's a preview of your BRÜTE!<br>Do you want to mint it or skip it?</b></h1>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      Logger.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#seelz-no").prop("checked", true);
      $('#bid-button').addClass("hidden")
      $("#login-button").html("Preview Mint")
      $('#login').addClass("hidden")
      $('#login-button').removeClass("is-loading")
      $("#bid-button").html("Preview Mint")
      document.getElementById("bid").classList.remove('hidden');
      
      
      

    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      //$( "#wait-time", identityWindow.window.document.body ).html(queueWaitTime)
      //identityWindow.document.$("#wait-time").html(queueWaitTime)
      //identityWindow.window.document.body.innerHTML
      //identityWindow.document.getElementById("time").innerText = queueWaitTime;
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)


    } else if (data.Status == "Accepting Bid") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      document.getElementById("instructions").classList.add('hidden');

    } else if (data.Status == "Minting") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        Logger.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
    } else if (data.Status == "Update") {
      Logger.log("Here's the status: " + data.Status);
      Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        Logger.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          Logger.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          Logger.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      Logger.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)
    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#clock").addClass("hidden")
      $("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      $("#instructions").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#login-button").html("Login with DeSo")
      $("#login-button").removeClass("is-loading")
      $("#login").removeClass("hidden", 500)
    } else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      //localStorage.setItem("Seelz", data.Seelz);
      Logger.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      nftSocket.close()
    } else if (data.Status == "NFTs are sold out!") {
      identityWindow.close()
      nftSocket.close()
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>BRÜTE is sold out!</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      Logger.log("Thinks nfts are sold out")


    }
    else if (data.Error) {
      Logger.log("Here's the error: " + data.Error);
      $("#instructions").addClass("hidden", 500)
      $("#options").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.Error + "</p>")
      setTimeout(function () {
        $("#bid-status").addClass("hidden", 500)
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").html("Mint NFT")
        $("#options").removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
      }, 10e3);

      Logger.log("Thinks there was an error")
      identityWindow.close()
    }
    else if (data.message == "Internal server error") {
      Logger.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.message + "</p>")

      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      Logger.log("Thinks there was an error")
      identityWindow.close()

    }


  };

  /*
  $.ajax({
      url: "https://z57p02t3oc.execute-api.us-west-2.amazonaws.com/production/mint-and-bid",
      type: "post",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(data),

      beforeSend: function(data) {
        // nothing
        Logger.log("beforeSend");
    },

    success: function (data) {
        Logger.log("Here's the TransactionHex");
        Logger.log(data);
        Logger.log("here's data 0");
        Logger.log(data[0]);
        // Created Transaction, now verify it
        if(typeof data[0] == 'undefined') {
          identityWindow.close();
          Logger.log("it is undefined");
          $("#bid-status").removeClass("hidden", 500).html('<p>Sorry, there was an error. Please try again later.</p>');
          $("#bid-button").html("Try Again");
          setTimeout(function() {
            $("#bid-status").addClass("hidden", 500);
        }, 5e3);
        Logger.log("Thinks there was an error");
        return false;
        }
        if(data == "No NFTs Available") {
          identityWindow.close();
          $("#bid-status").removeClass("hidden").html('<p>Sorry, there are no more NFTs available.</p>');
          $("#bid-button").addClass("hidden", 500);
          setTimeout(function() {
            $("#bid-status").addClass("hidden", 500);
        }, 5e3);
        Logger.log("No more NFTs");
        return false;
        }

        var transactionHex = data[0];
        var nftAddress = data[1];
        localStorage.setItem("transactionHex", transactionHex);
        localStorage.setItem("nftAddress", nftAddress);
        
        Logger.log("Trying to change location of identity window");
        identityWindow.location.href='https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
        //identityWindow = window.open('https://identity.deso.org/approve?tx='
        Logger.log("Thinks it sent")
        $("#bid-button").html("Mint NFT")
        $("#bid").addClass("hidden", 500)
        $("#instructions").addClass("hidden", 500)
        $("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")
      },
    error: function (jqXHR, textStatus, errorThrown) {
        // show an error message
        $("#bid-status").removeClass("hidden", 500).html('<p>Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status + '</p>')
        setTimeout(function() {
            $("#bid-status").addClass("hidden", 500)
        }, 5e3);
        $("#bid").removeClass("hidden", 500)
        Logger.log("Thinks there was an error")
    }
});
*/


}


function handleInit(e) {
  Logger.log("Init");
  Logger.log(e);
  if (!init) {
    init = true;
    iframe = document.getElementById("identity");

    for (const e of pendingRequests) {
      postMessage(e);
    }

    pendingRequests = []
  }

  respond(e.source, e.data.id, {})
}

function handleApprove(e) {
  Logger.log("Approve");
  Logger.log(e);
  if (!init) {
    init = true;
    iframe = document.getElementById("identity");

    for (const e of pendingRequests) {
      postMessage(e);
    }

    pendingRequests = []
  }
  localStorage.setItem("id", e.data.id);
  respondApprove(e.source, e.data.id, {})
}

function retryApprove(e) {
  identityWindow = window.open('https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex"), null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  //Logger.log("Trying to change location of identity window");
  //identityWindow.location.href='https://identity.deso.org/approve?tx=';
  //identityWindow = window.open('https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex"), null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  Logger.log("Thinks it sent")
  $("#bid-button").html("Mint NFT")
  $("#bid-button").removeClass("is-loading")
  $("#bid").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  $("#instructions").addClass("hidden", 500)
  $("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")
}

function handleLogin(payload) {
  Logger.log("Login");
  if(payload.publicKeyAdded) {
  Logger.log("We are at the handleLogin");
  Logger.log(JSON.stringify(payload))
  publicKey = payload.publicKeyAdded;
  Logger.log(publicKey);
  localStorage.setItem("userPublicKey", payload.publicKeyAdded);
  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;
  }



      // Get user name
      var settings = {
        "url": "https://node.deso.org/api/v0/get-single-profile",
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Content-Type": "application/json"
        },
        "data": JSON.stringify({
          "PublicKeyBase58Check": localStorage.getItem("userPublicKey")
        }),
      };

      $.ajax(settings).done(function (response) {

        var username = (response.Profile);
        localStorage.setItem("currentUser", username["Username"]);
        Logger.log(username["Username"]);
      });



      var data = {
        userPublicKey: localStorage.getItem("userPublicKey"),
        //derivedPublicKey: localStorage.getItem("derivedPublicKeyBase58Check"),
        //derivedSeedHex: localStorage.getItem("userEncryptedSeedHex"),
        //derivedJWT: localStorage.getItem("derivedJWT")
      }
      // Check that minter is not logged in first
      if(localStorage.getItem("userPublicKey") != 'BC1YLfiUNarmxM7BAGAFHTvp6oDgjF4hMP3ZaNhkdn1LdDhncWzQT6w') {
      $.ajax({
        url: "https://hjxcri2o7e.execute-api.us-west-2.amazonaws.com/production/initiate",
        type: "post",
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),

        beforeSend: function (data) {
          // nothing
          Logger.log("Initiating...");
        },

        success: function (data) {
          var mintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='rounded-circle purple-border mb-2' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You're all set to mint! Just click the button below and wait for your mint preview (please do not reload).</p>"
          var freeMintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='rounded-circle purple-border mb-2' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You have a free mint pass! Just click the button below and wait your mint preview (please do not reload).</p>"
          var userProfile = "\
                <a class='navbar-link'>\
                <figure class='image is-24x24'>\
                <img class='is-rounded' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png'>\
                </figure>\
                </a>\
                <div class='navbar-dropdown'>\
                <a class=navbar-item' onClick='logout()'>\
                Logout\
                </div>\
                </a>"
          $("#navbarlogin").html(userProfile);
          $("#navbarlogin").addClass("has-dropdown");
          $("#navbarlogin").addClass("is-hoverable");
          // Check free skip
          if (data[4] == "1") {
            Logger.log("free skip");
            localStorage.setItem("freeSkip", "1");
          } else {
            //Set free skip to false
            Logger.log(data)
            Logger.log("no free skip")
            localStorage.setItem("freeSkip", "0");
          }
          
          // check whitelist
          Logger.log("checking whitelist")
          Logger.log(data[1])
          if (data[1] == "1") {
            
            //check free pass
            if (data[3] == "1") {
              Logger.log("free mint pass");
              document.getElementById("login").classList.add('hidden');
              document.getElementById("bid").classList.remove('hidden');
              $("#login-button").html("Login with DeSo")
              $("#instructions").html(freeMintInstructions);
              $("#instructions").removeClass("hidden", 500);
              $("#options").removeClass("hidden", 500)
              $("#price").addClass("hidden", 500);
            }


            
            
              //check wallet
              else if (data[2] == "1") {

                if (data[0] == "1") {
                  $("#ban-status").removeClass("hidden").html('<p>Our system has detected that you tried to bypass our minting system by unapproving your derived DeSo key. If do do this again you will be banned for 24 hours. If you think this is in error, please reach out to us <a href="https://diamondapp.com/u/ClaraMouse" class="link-danger" target="_blank">@ClaraMouse</a></p>');
                  Logger.log("ban status 1");
                  document.getElementById("login").classList.add('hidden');
                  document.getElementById("bid").classList.remove('hidden');
                  $("#login-button").html("Login with DeSo")
                  $("#instructions").removeClass("hidden", 500);
                  $("#options").removeClass("hidden", 500)
                  $("#price").addClass("hidden", 500);

                  $("#instructions").html(mintInstructions);



                  //var transactionHex = data[0];
                  //var nftAddress = data[1];
                  //localStorage.setItem("transactionHex", transactionHex);
                  //localStorage.setItem("nftAddress", nftAddress);

                  //Logger.log("Trying to change location of identity window");
                  //identityWindow.location.href='https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
                  //identityWindow = window.open('https://identity.deso.org/approve?tx='
                  //Logger.log("Thinks it sent")
                  //$("#bid-button").html("Mint NFT")
                  //$("#bid").addClass("hidden", 500)
                  //$("#instructions").addClass("hidden", 500)
                  //$("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")

                } else if (data[0] == "3") {
                  $("#ban-status").removeClass("hidden").html('<p>Our system has detected that you have tried to bypass our minting system multiple times. You are banned from minting for the next 24 hours. If you think this is in error, please reach out to us <a href="https://diamondapp.com/u/ClaraMouse" class="link-danger" target="_blank">@ClaraMouse</a></p>');
                  Logger.log("ban status 2");
                  $("#instructions").addClass("hidden", 500)
                  $("#bid").addClass("hidden", 500)
                  $("#bid-button").addClass("hidden")
                } else {
                  Logger.log("Not on blacklist")
                  document.getElementById("login").classList.add('hidden');
                  document.getElementById("bid").classList.remove('hidden');
                  $("#login-button").html("Login with DeSo")
                  $("#instructions").removeClass("hidden", 500);
                  $("#options").removeClass("hidden", 500)
                  $("price").addClass("hidden", 500)
                  $("#instructions").html(mintInstructions, 500)
                  $("#bid-button").removeClass("hidden", 500)
                }
              

              } else {
                // not enough funds
                Logger.log("User does not have enough funds")
                $("#ban-status").removeClass("hidden").html("<p>Sorry, do not have enough funds to mint a Brüte. Please deposit funds into your DeSo account, reload this page and try again.</p>");
                document.getElementById("login").classList.add('hidden');
                $("#instructions").addClass("hidden", 500)
                $("#bid").addClass("hidden", 500)
                $("#bid-button").addClass("hidden")


              }
            
          } else {
            //user not on whitelist
            Logger.log("User not on whitelist")
            $("#bid-status").removeClass("hidden").html("<p class='error'>Minting will open to the public at 1000 hrs PDT / 1700 hrs UTC. Please reload at that time.</p>");
            document.getElementById("login").classList.add('hidden');
            $("#instructions").addClass("hidden", 500)
            $("#bid").addClass("hidden", 500)
            $("#bid-button").addClass("hidden")
          }



        },
        error: function (jqXHR, textStatus, errorThrown) {
          // show an error message

          Logger.log("Thinks there was an error checking blacklist")
        }
      });
    } else {
      // Minter is logged in - do not allow to mint
      Logger.log("Minter logged in")
            $("#ban-status").removeClass("hidden").html("<p>You cannot use the minter account to mint - please use another account.</p>");
            document.getElementById("login").classList.add('hidden');
            $("#instructions").addClass("hidden", 500)
            $("#bid").addClass("hidden", 500)
            $("#bid-button").addClass("hidden")

    }

  if (payload.hasOwnProperty('signedTransactionHex')) {
    Logger.log("payload is defined");
    Logger.log(payload);
    if (typeof payload.signedTransactionHex == 'undefined') {
      Logger.log("payload Signed Transaction is undefined - user cancelled transaction");
      $("#instructions").removeClass("hidden", 500);
      $("#instructions").html('You did not approve the bid transaction. Please click the button to try again. Please click "Approve" when the DeSo Identity window pops up.');
      $("#bid-button").attr("onclick", "retryApprove()");
      $("#bid-button").html("Retry Minting NFT");
      $("#bid-button").removeClass("hidden");
      $("#options").removeClass("hidden", 500)
      $("#bid-status").addClass("hidden", 500);
      $("#minted-nft").addClass("hidden", 500);
      return

    }
  }
} else {
Logger.log("At logout");
localStorage.setItem("userPublicKey", "");
  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;
  }
document.getElementById("login").classList.remove('hidden');
userProfile = "\
<a href='#' class='button punk-button' onClick='login();'>\
                        <span class='text'>Login</span>\
                        <span class='front-gradient'></span>\
                    </a>"
$("#login-button").removeClass("is-loading");
                  document.getElementById("bid").classList.add('hidden');
                  $("#login-button").html("Login with DeSo")
                  $("#instructions").addClass("hidden", 500);
                  $("#options").addClass("hidden", 500)
                  $("price").addClass("hidden", 500)
                  $("#bid-button").addClass("hidden", 500)
                  $("#navbarlogin").html(userProfile);
          $("#navbarlogin").removeClass("has-dropdown");
          $("#navbarlogin").removeClass("is-hoverable");

}
}

function handleDerive(payload) {
  Logger.log(payload);
  Logger.log("at derive")
  //if (identityWindow) {
  //identityWindow.close();
  //identityWindow = null;

  //var element = document.getElementById('loggedin');
  //element.innerText = 'Logged in with derived key as ' + payload.publicKeyBase58Check;
  //}



  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;


    if (payload.derivedSeedHex && mintType == 'mint') {
      Logger.log("have derived key")
      publicKey = payload.publicKeyBase58Check;


      localStorage.setItem("userPublicKey", payload.publicKeyBase58Check);
      localStorage.setItem("derivedPublicKeyBase58Check", payload.derivedPublicKeyBase58Check);
      localStorage.setItem("derivedJWT", payload.derivedJwt);
      localStorage.setItem("derivedSeedHex", payload.derivedSeedHex);
      localStorage.setItem("accessSignature", payload.accessSignature);
      localStorage.setItem("expirationBlock", payload.expirationBlock);
      localStorage.setItem("transactionSpendingLimitHex", payload.transactionSpendingLimitHex);
      Logger.log("Local Storage");
      Logger.log(localStorage.getItem("userPublicKey"));
      Logger.log(localStorage.getItem("derivedSeedHex"));

      //Now we are minting because we clicked mint
      selectMint();


    } else if(payload.derivedSeedHex  && mintType == 'skip') {
      Logger.log("want to skip and have derived key ready")
      localStorage.setItem("userPublicKey", payload.publicKeyBase58Check);
      localStorage.setItem("derivedPublicKeyBase58Check", payload.derivedPublicKeyBase58Check);
      localStorage.setItem("derivedJWT", payload.derivedJwt);
      localStorage.setItem("derivedSeedHex", payload.derivedSeedHex);
      localStorage.setItem("accessSignature", payload.accessSignature);
      localStorage.setItem("expirationBlock", payload.expirationBlock);
      localStorage.setItem("transactionSpendingLimitHex", payload.transactionSpendingLimitHex);
      Logger.log("Local Storage");
      Logger.log(localStorage.getItem("userPublicKey"));
      Logger.log(localStorage.getItem("derivedSeedHex"));
      Logger.log("Run Skip")
      skipMint();

    } else {
      Logger.log("No derived key");
    }
  }
}





function respond(e, t, n) {
  Logger.log("Respond");

  e.postMessage({
    id: t,
    service: "identity",
    payload: n
  }, "*")
}

function respondApprove(e, t, n) {
  Logger.log("Respond Approve");

  e.postMessage({
    id: t,
    method: "sign",
    service: "identity",
    payload: n
  }, "*")
  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;
  }
}

function postMessage(e) {
  init ? this.iframe.contentWindow.postMessage(e, "*") : pendingRequests.push(e)
}

// const childWindow = document.getElementById('identity').contentWindow;
window.addEventListener('message', message => {
  Logger.log("constant");
  Logger.log('message: ');
  Logger.log(message);

  const { data: { id: id, method: method, payload: payload } } = message;

  Logger.log(id);
  Logger.log(method);
  Logger.log(payload);

  if (payload?.signedTransactionHex) {
    Logger.log("Got Signed");
    if (identityWindow) {
      identityWindow.close();
      identityWindow = null;
    }

    Logger.log(payload.signedTransactionHex);
    var signedTransactionHex = payload.signedTransactionHex;
    var userPublicKey = localStorage.getItem("userPublicKey");
    Logger.log("User Public Key");
    Logger.log(userPublicKey);
    var userEncryptedSeedHex = localStorage.getItem("userEncryptedSeedHex");
    var userAccessLevelHmac = localStorage.getItem("userAccessLevelHmac");
    var transactionHex = localStorage.getItem("transactionHex");
    var nftAddress = localStorage.getItem("nftAddress");
    //var Seelz = localStorage.getItem("Seelz");
    var dao = "";
    var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");


  
  
    nftSocket = new WebSocket("wss://gug5ltumv8.execute-api.us-west-2.amazonaws.com/production");
    nftSocket.onopen = function (e) {
      Logger.log("Connected to socket again");
      Logger.log("Sending message to initiate signing bid");

      //nftSocket.send(message);


      //socket.send(message);
      //var data = {
      //  action : "update"}
      //var message = JSON.stringify(data);
      //socket.send(message);
    };
    var data = {
      action: 'bidwebsocket',
      type: 'website',
      dao: dao,
      userPublicKey: userPublicKey,
      derivedPublicKey: localStorage.getItem("derivedPublicKeyBase58Check"),
      signedTransactionHex: signedTransactionHex,
      userEncryptedSeedHex: userEncryptedSeedHex,
      userAccessLevelHmac: userAccessLevelHmac,
      transactionHex: transactionHex,
      nftAddress: nftAddress
    }

    var message = JSON.stringify(data);
    sendMessage(nftSocket, message)
    sendMessage(nftSocket, JSON.stringify({ action: 'update' }))



    nftSocket.onmessage = function (event) {
      Logger.log("Message received from websocket");
      Logger.log(event.data);
      data = JSON.parse(event.data);
      if (data.Status == "Accepting Bid") {
        Logger.log("Here's the status: " + data.Status);
        Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
        document.getElementById("instructions").classList.add('hidden');
      } else if (data.Status == "Minting") {
        Logger.log("Here's the status: " + data.Status);
        Logger.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      } else if (data.Status == "NFTs are sold out!") {
        identityWindow.close()
        nftSocket.close()
        $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Brütes are sold out!</p>")
        $("#ban-status").addClass("hidden", 500)
        $("#bid-button").html("Mint NFT")
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").addClass("hidden", 500)
        $("#instructions").addClass("hidden", 500)
        Logger.log("Thinks nfts are sold out")



      } else if (data.Status == "Update") {
        Logger.log("Here's the status: " + data.Status);
        Logger.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
        if (data.ApproximateNumberOfMessages == 0) {
          Logger.log("Wait time is 0");
          var queueWaitTime = "60";
        } else {
          var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
          if (queueWaitTime < 60) {
            Logger.log("Wait time is less than a minute");
            queueWaitTime = "60";
          } else {
            Logger.log("Wait time is greater than a minute");
            queueWaitTime = queueWaitTime.toString();

          }
        }
        //identityWindow.opener.$("#wait-time").html(queueWaitTime)
        Logger.log("Writing wait time")
        $("#clock").html("Estimating wait time...")
        clock(queueWaitTime);
        $("#clock").removeClass("hidden", 500)
        $("#processing-quote").removeClass("hidden", 500)
      }
      else if (data.Status == "Ready to sign") {
        localStorage.setItem("transactionHex", data.BidTransferHex);
        localStorage.setItem("nftAddress", data.postHashHex);
        //localStorage.setItem("Seelz", data.Seelz);
        Logger.log("Trying to change location of identity window");
        identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      }
      else if (data.Error) {
        Logger.log("Here's the error: " + data.Error);
        $("#clock").addClass("hidden")
        $("#processing-quote").addClass("hidden")
        clearInterval(myTimer)
        $("#instructions").addClass("hidden", 500)
        $("#bid").addClass("hidden", 500)
        $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
        $("#bid-button").html("Mint NFT")
        $("#bid-button").removeClass("is-loading")
        $("#login-button").html("Login with DeSo")
        setTimeout(function () {
          $("#bid-status").addClass("hidden", 500)
          $("#login").removeClass("hidden", 500)
        }, 5e3);
      } else if (data.message == "Internal server error") {
        Logger.log("Here's the error: " + data.message);
        $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
        setTimeout(function () {
          $("#bid-status").addClass("hidden", 500)
        }, 5e3);
        $("#bid-button").html("Mint NFT")
        $("#bid-button").removeClass("is-loading")
        $("#options").removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
        Logger.log("Thinks there was an error")
        identityWindow.close()

      }
      else if (data.Status == "Complete") {
        Logger.log("Completed minting")
        $("#clock").addClass("hidden")
        $("#processing-quote").addClass("hidden")
        clearInterval(myTimer)
        nftSocket.close()
        Logger.log("NFT Attributes 2")
        Logger.log(data)
        Logger.log(data.Attributes.Background)
        var nftID = data.Attributes.Edition;
        var background = data.Attributes.Background;
        var head = data.Attributes.Head;
        var nose = data.Attributes.Nose;
        var head = data.Attributes.Head;
        var top = data.Attributes.Top;
        var bottom = data.Attributes.Bottom;
        var traitCount = data.Attributes.TraitCount;
        var rarityScore = data.Attributes.RarityScore;
        var animal = data.Attributes.Animal;
        var imageURL = data.Attributes.ImageURL;
        var PostHashHex = data.Attributes.PostHashHex;
        nftCard = " \
        <div class='card'>"
        document.getElementById("instructions").classList.add('hidden');

        $("#bid-status").removeClass("hidden").html("<p>You've minted an NFT!</p>")
        $("#minted-nft").removeClass("hidden").html(nftCard)
        Logger.log("Thinks it sent inside signed")
        $("#options").removeClass("hidden", 500)
        $("#name").val("")
        $("#seelz-no").prop("checked", true);
        $("#bid-button").html("Mint one more")


        //Confetti
        var count = 200;
        var defaults = {
          origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
          confetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
          }));
        }

        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        fire(0.2, {
          spread: 60,
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        });
      }


    };



    /*
    $.ajax({
        url: "https://z57p02t3oc.execute-api.us-west-2.amazonaws.com/production/accept-bid",
        type: "post",
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
 
        beforeSend: function(data) {
          // nothing
      },
 
      success: function (data) {
          Logger.log(data);
          if(data == "Error - could not accept bid") {
            $("#instructions").addClass("hidden", 500)
            $("#bid").addClass("hidden", 500)
            $("#bid-status").removeClass("hidden", 500).html("<p>Sorry, your bid timed out. Please try again.</p>")
            $("#bid-button").html("Mint NFT")
            $("#login-button").html("Login with DeSo")
            setTimeout(function() {
                $("#bid-status").addClass("hidden", 500)
                $("#login").removeClass("hidden", 500)
              }, 5e3);
        return
          }
          document.getElementById("instructions").classList.add('hidden');
          $("#bid-status").removeClass("hidden").html("<p>You've minted an NFT!</p>")
          Logger.log("Thinks it sent inside signed")
          $("#bid-button").html("Mint one more")
          document.getElementById("bid").classList.remove('hidden');
          //Confetti
var count = 200;
var defaults = {
origin: { y: 0.7 }
};
 
function fire(particleRatio, opts) {
confetti(Object.assign({}, defaults, opts, {
  particleCount: Math.floor(count * particleRatio)
}));
}
 
fire(0.25, {
spread: 26,
startVelocity: 55,
});
fire(0.2, {
spread: 60,
});
fire(0.35, {
spread: 100,
decay: 0.91,
scalar: 0.8
});
fire(0.1, {
spread: 120,
startVelocity: 25,
decay: 0.92,
scalar: 1.2
});
fire(0.1, {
spread: 120,
startVelocity: 45,
});
          
        },
      error: function (jqXHR, textStatus, errorThrown) {
          // show an error message
          $("#bid-status").removeClass("hidden").html('<p>Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status + '</p>')
          setTimeout(function() {
            $("#bid-status").addClass("hidden", 500)
          }, 5e3);
          $("#bid-button").html("Mint a NFT")
          Logger.log("Thinks there was an error")
          
      }
      
      
  });
  */





  }
  else if (method == 'initialize') {
    handleInit(message);
  } else if (method == 'login') {
    handleLogin(payload);
  } else if (method == 'derive') {
    handleDerive(payload);
  }
});

var init = false;
var iframe = null;
var pendingRequests = [];
var identityWindow = null;

function bid1() {
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  e = $(this).siblings(".bid")
  var o;
  var userPublicKey = localStorage.getItem("userPublicKey");
  var data = {
    userPublicKey: userPublicKey
  }
  $.ajax({
    url: "https://z57p02t3oc.execute-api.us-west-2.amazonaws.com/production/mint-and-bid",
    type: "post",
    dataType: "json",
    crossDomain: "true",
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(data),

    beforeSend: function (data) {
      // nothing
    },

    success: function (data) {
      Logger.log(data);
      //o = "<p class='form-message form-success'>You're on the whitelist!</p>";
      //e.addClass("hidden").html(o)


      //Logger.log("Thinks it sent to whitelist")
      //$("#whitelist-button").html("Join Whitelist")
      //$("#whitelistform").addClass("hidden")
      //$("#whitelist-status").removeClass("hidden").html("<p>You're on the whitelist!</p>")

    },
    error: function (jqXHR, textStatus, errorThrown) {
      // show an error message
      var errorMessage = 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
      o = '<p class="form-message form-error">' + errorMessage + '</p>';
      e.removeClass("hidden").html(o);
      setTimeout(function () {
        e.addClass("hidden", 500).html("");
      }, 5e3);
      $('#submit-whitelist').attr('disabled', false);
      Logger.log("Thinks there was an error")
      $("#whitelist-button").html("Join Whitelist")

    }
  });


}
